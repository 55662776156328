.article {
   padding: 2rem 0 0 0;

   .advertising {
      display: block;
      width: 100%;
      height: auto;
      min-height: 15rem;
      background-color: lawngreen;
   }

   .btn_box {
      margin: 2rem 0 4rem 0;
   }

   .wrapper {
      max-width: $body_width;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 100%;
      padding: 0 1rem;

      h1 {
         font-size: 2.8rem;
         color: lighten($font_color_dark, 8);
         margin: 2rem 0;
      }

      .tag_row {
         margin: 2rem 0;
      }

      .img_box {
         height: 55rem;
         position: relative;

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
         }

         .inner_box {
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            display: inline-flex;

            .photo {
               margin-right: .7rem;
            }

            .photo,
            .comments {
               background-color: darken($base_color_5, 10);

               &:hover {
                  background-color: lighten($base_color_5, 2);
               }

               a {
                  display: block;
                  color: $base_color_1;
                  font-size: 1.5rem;
                  font-weight: 700;
                  padding: .5rem 1.3rem;
               }
            }
         }

      }

      .info_row {
         display: grid;
         grid-template-columns: fit-content(60rem) auto;
         align-items: center;
         justify-content: space-between;
         border-bottom: 1px solid darken($border_color, 20);
         font-size: 1.6rem;
         font-weight: 600;
         color: $font_color;
         padding: 2.5rem 0;

         &.bottom {
            border-bottom: 0;
         }

         .left {
            span:not(:last-child) {
               margin-right: 1rem;
            }
         }

         .right {
            display: inline-flex;
            align-items: center;

            a:not(:last-child) {
               margin-right: 1rem;

               img {
                  height: 2.5rem;
                  width: auto;
               }
            }

            .fb_btn {
               margin-left: 1rem;
            }
         }
      }

      h2 {
         display: block;
         font-size: 2.2rem;
         color: $font_color_dark;
         margin: 2rem 0;
         line-height: 1.4;

         &.lightgrey {
            color: $font_color;
            font-size: 2.4rem;
         }
      }

      p {
         display: block;
         font-size: 1.6rem;
         color: darken($font_color, 5);
         margin: 2rem 0;
         line-height: 1.6;
      }

      .boxes {
         display: grid;
         grid-template-columns: 100%;
         grid-gap: 2rem;

         .box {
            .img_box {
               max-height: 20rem;

               img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
               }
            }

            p {
               margin: 1rem 0;
            }

            .autor {
               font-size: 1.6rem;
               color: darken($font_color, 5);
               font-weight: 700;
            }
         }
      }
   }

   .coments_row {
      background-color: lighten($font_color_light, 10);
      padding: 0 1rem;

      .comment_block {
         max-width: $body_width;
         width: 100%;
         margin: 0 auto;
         padding: 2rem 0;

         h2 {
            display: block;
            font-size: 2rem;
            color: $font_color;
            margin: 2rem 0;
            line-height: 1.4;
         }
      }
   }
}

@media (min-width: $break_tablet) {
   .article {
      .wrapper {
         .boxes {
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 2rem;
         }
      }
   }
}

@media (min-width: $break_desktop) {
   .article {
      .wrapper {
         grid-template-columns: auto $sidebar_width;
         grid-column-gap: 2rem;
      }
   }

   .siderbar {
      margin-top: 3rem;
   }
}
