.mohlo_by_zajimat {
   background-color: $section_bg;
   padding: 2rem 1rem;

   .content {
      max-width: $body_width;
      width: 100%;
      margin: 0 auto;

      h2 {
         color: $font_color;
         font-weight: 700;
         font-size: 2.6rem;
         margin-bottom: 3rem;
      }

      .section_wrapper {
         display: grid;
         grid-template-columns: 100%;
         grid-row-gap: 2rem;

         .box {
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;

            .img_box {
               position: relative;
               margin-bottom: 1rem;

               .overlay {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  height: 100%;
                  width: 100%;
                  opacity: 0;
                  transition: .5s ease;
                  background: rgba($base_color_3, .5);
               }

               &:hover .overlay {
                  opacity: 1;
               }

               .like {
                  display: block;
                  position: absolute;
                  top: 2rem;
                  right: 2rem;
                  width: 2.5rem;
                  height: 2rem;

                  span {
                     display: block;
                     width: 2.5rem;
                     height: 2rem;
                     background: url("../img/icon/srdce.png") center center no-repeat;
                     transition: all .2s;
                  }

                  &:hover span {
                     background: url("../img/icon/srdce-hover.png") center center no-repeat;
                     transition: all .2s;
                  }
               }
            }

            .title {
               margin-bottom: 1rem;
            }

            @include info_block;

            @include content_block;

            .content {
               margin: 1rem 0
            }
         }
      }
   }
}

@media (min-width: $break_tablet) {
   .mohlo_by_zajimat {
      padding: 4rem 1rem;

      .content {
         .section_wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 2rem;
         }
      }
   }
}

@media (min-width: $break_desktop) {
   .mohlo_by_zajimat {
      .content {
         .section_wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 2rem;
         }
      }
   }
}