.nejctenejsi {
   background-color: $base_color_1;

   h2 {
      color: $font_color;
      font-weight: 700;
      font-size: 35rem;
      margin-bottom: 1.5rem;
   }
   .column {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 2rem;
      margin: 2rem 0;
   }

   .box {
      display: flex;
      flex-direction: column;

      .article_box {
         position: relative;
         height: inherit;

         &:hover {
            cursor: pointer;

            .info {
               margin: auto;
               min-height: 50%;
               transition: all .2s;
            }
         }

         .info {
            min-height: 30%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgba($base_color_4, .75);
            color: $base_color_1;
            font-size: 2rem;
            padding: 2rem;

            .date {
               font-size: 1.4rem;
               color: $font_color_light;
               font-weight: 700;
            }
         }
      }
   }
}

@media (min-width: $break_tablet) {
   .nejctenejsi {
      .column {
         grid-template-columns: repeat(2, 1fr);
      }
   }
}

@media (min-width: $break_desktop) {
   .nejctenejsi {
      .column {
         grid-template-columns: 100%;
      }
   }
}