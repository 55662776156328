/* PROJECT UNITS */
$body_width: 120rem;

$break_tablet: 600px;
$break_desktop: 1200px;

$respo_padding: 1rem;

$sidebar_width: 27.5rem;

/* PROJECT COLORS */
$base_color_1: #ffffff;
$base_color_2: #3c73b9;
$base_color_3: #000000;
$base_color_4: #0e242c;
$base_color_5: #333333;

$font_color_light: #c8c8c8;
$font_color: #888888;
$font_color_dark: #333333;

$border_color: #bbbbbb;

$section_bg: #ebebeb;

$tag_color_1: #fe2a60;
$tag_color_2: #05abce;
$tag_color_3: #ffc240;
$tag_color_4: #45a281;
$tag_color_5: #6d6295;