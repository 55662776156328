@mixin info_block {
   .info {
      display: inline-flex;
      align-items: center;
      height: 2.6rem;

      .tag {
         margin: 0;
      }

      .date {
         color: $font_color;
         font-size: 1.4rem;
         margin: 0 1rem;
         font-weight: 700;
      }

      .msg {
         display: flex;
         align-items: center;
         height: 3rem;
         background: url("../img/icon/zprava.png") left center no-repeat;
         padding-left: 2.5rem;
         font-size: 1.4rem;
         color: $base_color_2;
         font-weight: 700;
      }
   }
}

@mixin content_block {
      .text {
         font-size: 1.6rem;
         color: $font_color;

         &.bold {
            color: $font_color_dark;
            font-size: 2rem;
         }
      }
   }